@media (max-width: $screen-sm) {
    // sm-view
    html {
        font-size: 12px;
        line-height: 140%;
    }

    .wrapper {
    	min-width: 290px;
    	max-width: 100%;
    }

    .wrapper-inside {
        width: 90.625%;
    }

    /* Header
    -----------------------------------------------------------------------------*/
    .header {
        height: $header-size-xs;
    }

    /* Middle
    -----------------------------------------------------------------------------*/
    .content {
        padding: 0 0 $footer-size-xs;
    }

    /* Footer
    -----------------------------------------------------------------------------*/
    .footer {
        margin: -$footer-size-xs auto 0;
	    height: $footer-size-xs;
    }

    h1, .h1 {
        font-size: 28px;
    }

    h2, .h2 {
        font-size: 18px;
        line-height: 25px;
    }

    h3, .h3 {
        font-size: 12px;
        line-height: 16px;
    }

    p {
        font-size: 12px;
        margin: 0 0 16px;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
