@media (max-width: $screen-sm) {
    // sm-view
    form {
        .action {
            .submit {
                .btn {
                    font-size: 16px;
                    line-height: 22px;
                    padding: 10px 0;
                }
            }
        }

        .form-group {
            width: 100%;
            margin-left: 0;
            .form-col {
                padding: 0;
                &.col-33 {
                    width: 100%;
                }
                &.col-50 {
                    width: 100%;
                }
            }
        }

        .form-field {
            margin-bottom: 4px;
            label.input {
                input, textarea {
                    height: 37px;
                    font-size: 12px;
                    @include placeholder {
                        font-size: 12px;
                    }
                }
            }

            label.checkcontainer {
                .name {
                    font-size: 12px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
