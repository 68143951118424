@import "../mixins";

$menu-width: 247px;

/*Mobile menu
-----------------------------------------------------------------------------*/
.mobile-menu {
	.mobile-menu-holder {
		.menu-background {
			position: fixed;
			z-index: 1201;
			top: 0;
			left: 0;
			background: $gray-base;
			opacity: 0.64;
			width: 100%;
			height: 100%;
			@include transition-easy-in-out(250ms);
			&.animated {
				opacity: 0;
				@include transition-easy-in-out(250ms);
			}
			&.closed {
				display: none;
			}
		}
		.menu-body {
			position: fixed;
			z-index: 1202;
			top: 0;
			right: 0;
			display: inline-block;
			float: right;
			width: $menu-width;
			height: 100%;
			box-sizing: border-box;
			padding: 0;
			background: white;
			opacity: 1;
			transform: translateX(0);
			overflow-y: auto;
			padding: 68px 0 0;
			@include transition-easy-in-out(350ms);
			&.animated {
				transform: translateX($menu-width);
				@include transition-easy-in-out(350ms);
			}
			&.closed {
				display: none;
			}
            &.mobile-offset {

            }
			>.heading {
				background: white;
				display: inline-block;
				width: 100%;
				padding-top: 0;
			    padding-left: 0;
			    padding-bottom: 0;
			    margin-bottom: 0;
				box-sizing: border-box;
				background: $gray-lighter;
				a.close {
	                display: inline-block;
                    float: left;
	                color: $brand-primary;
					width: 43px;
					height: 43px;
					border: 2px solid $brand-primary;
					border-radius: 4px;
					line-height: 40px;
					text-align: center;
	                svg {
	                    width: 21px;
	                    height: 17px;
	                    vertical-align: middle;
	                    fill: currentColor;
						transform: translate(-1px, -1px);
	                    @include transition-easy-in-out(350ms);
	                }
	                &:hover, &:focus {
						background: $brand-primary;
						color: white;
	                    svg {
	                        @include transition-easy-in-out(350ms);
	                        transform: rotate(180deg);
							fill: currentColor;
	                    }
	                }
	            }
			}
			.main-body {
				display: block;
				overflow-y: hidden;
			    overflow-x: hidden;
			    padding-top: 0;
				height: auto;
			}
            /*naib body contents*/
			nav.mobile-main-menu-nav { 

				ul { 
					display: inline-block;
					width: 100%;
					list-style: none;
					box-sizing: border-box;
					padding: 0 15px;
					margin: 0 0 30px;
					li { 
						display: inline-block;
						width: 100%;
						text-align: right;
						margin: 0 0 11px;
						a {
							display: inline-block;
							font-style: normal;
							font-weight: 400;
							font-size: 12px;
							line-height: 140%;
							color: $brand-secondary;
							position: relative;
							&:hover {
								color: $brand-primary;
							}
							&:after {
								content: "";
								display: inline-block;
								width: 100%;
								height: 0;
								border-top: 1px solid currentColor;
								position: absolute;
								left: 0;
								bottom: 0;
								opacity: 0;
								transition: all 300ms;
							}
							&.current { 
								&:hover {
									color: $brand-secondary;
									cursor: default;
								}
								&:after {
									opacity: 1;
									transition: all 300ms;
								}
							}
						}

					}
				}
			}
			
			.contact-infoes { 
				display: inline-block;
				width: 100%;
				box-sizing: border-box;
				padding: 0 15px;
				.contact-info { 
					display: inline-block;
					width: 100%;
    				text-align: right;
					margin: 0 0 10px;
					a { 
						display: inline-block;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 140%;
                        &:hover {
                            color: $brand-primary;
                        }
                        .icon { 
                            float: left;
                            margin-right: 10px;
                            svg { 
                                margin: -3px 0 0;
                            }
                        }

                        .text { 
                            float: left;
                        }
					}
				}
			}
		}
	}
}

@import "../media/mobile/includes/mobile_menu";
@import "../media/tablet/includes/mobile_menu";
