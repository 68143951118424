.slick-prev, .slick-next {
    display: none;
    &.slick-arrow {
        display: flex;
    }
}

.slick-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% - 15px));
    z-index: 1001;
    font-size: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
    color: $gray-base;
    transition: all 300ms ease;
    box-sizing: border-box;
    &.slick-hidden {
        opacity: 0;
        z-index: -1;
    }
    &.slick-disabled {
        color: $gray-light;
        cursor: default;
        svg {
            opacity: 0.3;
        }
        &:hover {
            color: #3D3D3D;
        }
    }
    &.slick-prev, &.prev {
        left: -19px;
    }
    &.slick-next, &.next {
        right: -19px;
        svg {
            transform: rotate(180deg);
        }
    }
    &:focus {
        outline: none;
    }
    &:hover {
        color: $brand-secondary-accent;
        transition: all 300ms ease;
    }
    svg {
        width: 35px;
        height: 16px;
        vertical-align: middle;
    }
}

ul.slick-dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    li {
        display: inline-block;
        float: left;
        margin: 0 6px;
        &.slick-active {
            button {
                cursor: default;
                background-color: $brand-secondary;
                &:before {
                    opacity: 1;
                    transition: all 300ms ease;
                }
                &:hover {
                    opacity: 1;
                }
            }
        }
        button {
            font-size: 0;
            position: relative;
            width: 14px;
            height: 14px;
            background-color: white;
            border: 2px solid $brand-secondary;
            box-sizing: border-box;
            border-radius: 4px;
            transition: all 300ms ease;
            cursor: pointer;
            padding: 0;
            &:focus {
                outline: none;
            }
            &:hover {
                background-color: $brand-secondary;
                opacity: 0.64;
                transition: all 300ms ease;
            }
        }
    }
}

.slick-slider {
    width: calc(100% + 30px);
    margin-left: -15px;
    position: relative;
    .slick-list {
        height: 100%;
        overflow: hidden;
        &:after{
          clear: both;
          content: "";
          display: block;
        }
        .slick-track {
            height: 100%;
            .slick-slide {
                display: inline-block;
                float: left;
                height: 100%;
                box-sizing: border-box;
                padding: 0 15px;
                >div {
                    height: 100%;
                }
            }
        }
    }
}
.slick-slider.full {
    width: 100%;
    margin: 0;
    .slick-list {
        .slick-track {
            .slick-slide {
                padding: 0;
            }
        }
    }
}
.slick-slider.auto-height {
    .slick-list {
        height: auto;
        .slick-track {
            height: auto;
            .slick-slide {
                height: auto;
                padding: 0;
                >div {
                    height: auto;
                }
            }
        }
    }
}
.slick-slider.disabled-slider {
    .slick-list {
        .slick-track {
            width: auto !important;
            transform: translate3d(0, 0px, 0px) !important;
        }
    }
}

.common-slider {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 0;
    .prev, .next {
        display: none;
        .slick-arrow {
            display: flex;
        }
    }
}

// @import "../media/tablet/includes/slick";
// @import "../media/mobile/includes/slick";
