.custom-tabs {
    .tab-selector {
        &>* {
            display: inline-block;
            float: left;
            margin: 0 20px;
            &.current {
                color: red;
            }
        }
    }

    .tabs {
        display: inline-block;
        width: 100%;
        position: relative;
        min-height: 200px;
        overflow: hidden;
        .tab {
            display: inline-block;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            z-index: -1;
            transition: all 300ms;
            &.animated {
                position: relative;
                z-index: 1;
                transition: all 300ms;
            }
            &.current {
                opacity: 1;
                position: relative;
                transition: all 300ms;
                z-index: 2;
            }
        }
    }
}
