@media (max-width: $screen-lg) {
    // lg-md view
    .footer-holder { 
        .footer-main { 
            .footer-main-holder { 
                .company { 
                    width: 100%;
                    .company-holder { 
                        padding: 20px 0 0;
                        .logo {
                            margin: 0 0 10px;
                        }

                        .copyright {
                            font-size: 14px;
                        }
                    }
                }

                .footer-menu { 
                    margin: 0;
                    .footer-menu-holder { 
                        padding: 23px 0 0;
                        nav {
                            ul {
                                padding: 0;
                                margin: 0;
                                li {
                                    margin: 0 0 16px;
                                }
                            }
                        }
                    }
                }

                .contacts-infoes { 
                    padding: 47px 0 0;
                    margin-right: 0;
                    .contacts-infoes-holder { 
                        .contact-info { 
                            margin: 0 0 8px;
                        }
                    }
                }
            }
        }

        .footer-bottom { 
            padding: 4px 0 0;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
