.footer-holder { 
    display: inline-block;
    width: 100%;
	.footer-main { 
        display: inline-block;
        width: 100%;
        padding: 0 0 4px;
		.footer-main-holder { 
            display: inline-block;
            width: 100%;
            .company { 
                float: left;
                .company-holder { 
                    padding: 66px 0 0;
                    .logo { 
                        margin: 0 0 26px;
                        a { 

                            svg { 

                                path { 

                                }
                            }
                        }
                    }

                    .copyright { 
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 140%;
                        color: #A0A0A0;
                    }
                }
            }

            .footer-menu { 
                float: left;
                margin-left: 154px;
                .footer-menu-holder { 
                    padding: 43px 0 0;
                    nav { 

                        ul { 
                            list-style: none;
                            li { 
                                margin: 0 0 17px;
                                a {     
                                    color: white;
                                    &:hover {
                                        color: $brand-primary;
                                    }
                                }   
                            }
                        }
                    }
                }
            }

            .contacts-infoes { 
                float: right;
                width: 450px;
                padding: 78px 0 0;
                margin-right: -3px;
                .contacts-infoes-holder { 
                    display: inline-block;
                    width: 100%;
                    .contact-info { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 9px;
                        a, .dummy { 
                            display: block;
                            float: right;
                            color: white;
                            &:hover {
                                color: $brand-primary;
                            }
                            .icon { 
                                float: left;
                                width: 24px;
                                height: 24px;
                                svg { 
                                    margin: -3px 0 0;
                                    path { 

                                    }
                                }
                            }

                            .text { 
                                width: calc(100% - 24px);
                                float: left;
                                box-sizing: border-box;
                                padding-left: 10px;
                            }
                        }

                        .dummy {
                            color: white;
                            &:hover {
                                color: white;
                            }
                        }
                    }
                }
            }
        }
	}

	.footer-bottom { 
        display: inline-block;
        width: 100%;
        border-top: 1px solid white;
        padding: 10px 0 0;
		.footer-bottom-holder { 
            display: inline-block;
            width: 100%;
            .policy { 
                float: left;
                padding: 10px 0 0;
                a { 
                    color: white;
                    &:hover {
                        color: $brand-primary;
                    }
                }
            }

            .studio { 
                float: right;
                a { 
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    text-align: right;
                    letter-spacing: 0.02em;
                    color: white;
                    &:hover {
                       color: $brand-primary;
                    }
                    .text {
                        float: left;
                        padding: 7px 0 0;
                    }

                    .icon {
                        float: left;
                        margin-left: 10px;
                    }
                }
            }
        }
	}
}

@import "../../media/tablet/includes/base/footer";
@import "../../media/mobile/includes/base/footer";
