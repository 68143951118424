/* Customize the label (the container) */
.checkcontainer.black {
    &:hover input ~ .checkmark {
        border: 1px solid $brand-primary;
    }
    .checkmark {
        border: 1px solid black;
        &::after {
            border: solid black;
            border-width: 0 2px 2px 0;
        }
    }
}
.checkcontainer {
    width: 100%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.radio {
        .checkmark {
            border-radius: 50%;
        }
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
            &::after {
                opacity: 1;
                transition: all 300ms ease;
            }
        }
    }
    &:hover input ~ .checkmark {
        border: 1px solid $brand-primary;
        transition: all 300ms;
    }
    .checkmark {
        display: inline-block;
        float: left;
        left: 0px;
        top: 0px;
        height: 16px;
        width: 16px;
        box-sizing: border-box;
        background-color: transparent;
        border: none;
        border-radius: 5px;
        border: 1px solid white;
        position: relative;
        transition: all 300ms;
        &::after {
            content: "";
            position: absolute;
            display: inline-block;
            opacity: 0;
            left: 50%;
            top: 50%;
            width: 2px;
            height: 6px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: translate(-50%, -50%) rotate(45deg);
            -ms-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
            transition: all 300ms ease;
        }
    }
    .name {
        display: inline-block;
        float: left;
        width: calc(100% - 16px - 5px);
        margin-left: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
    }
}
