@media (max-width: $screen-lg) {
    // lg-md view
    form {
        .form-group {
            width: calc(100% + 20px);
            margin-left: -10px;
            .form-col {
                padding: 0 10px;
            }
        }

        .form-field {
            label.input {
                input, textarea {
                    height: 40px;
                    font-size: 14px;
                    @include placeholder {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
