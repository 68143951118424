.fancybox-container.custom-fancybox {
    .fancybox-content {
        padding: 30px;
        overflow: initial;
    }
    button.fancybox-button.fancybox-close-small {
        display: none;
    }
}

.popup {
    position: relative;
    .custom-close {
        position: absolute;
        right: 16px;
        top: 16px;
        a {
            display: inline-block;
            text-decoration: none;
            svg {
                width: 16px;
                height: 16px;
                color: black;
                transition: all 300ms;
            }
            &:hover {
                svg {
                    color: $brand-primary;
                    transition: all 300ms;
                }
            }
        }
    }
}

.general-popup {
    max-width: 590px;
    min-width: 290px;
    background: #FFFFFF;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 30px;
    .general { 
        display: inline-block;
        width: 100%;
        .heading { 
            display: inline-block;
            width: 100%;
            text-align: center;
            padding: 30px 0 15px;
            h2, .h2 { 
         
            }
        }
        .text {
            max-height: 75vh;
            overflow-y: auto;
            overflow-x: hidden;
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 140%;
                margin: 0 0 9px;
            }
        }
        form { 
            display: inline-block;
            width: 100%;
            .form-group { 
    
                .form-col { 
    
                    .form-field { 
                        margin: 0 0 10px;
                        label.input { 
    
                            .name { 
    
                            }
    
                            input { 
                                border: 1px solid #E8E8E8;
                                &:hover {
                                    border: 1px solid $brand-primary;
                                }
                            }
                        }

                        label.checkcontainer {
                            margin: 5px 0 5px;
                        }
                    }
                }
            }
    
            .action { 
    
                .submit { 
    
                    .btn { 
    
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/popups/custom_fancybox";
@import "../../media/mobile/includes/popups/custom_fancybox";
