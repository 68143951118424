@media (max-width: $screen-sm) {
    // sm-view
    .header-holder { 
        .header-top { 
            .header-top-holder { 
                .logo {
                    padding: 10px 0 0;
                    a {
                        svg {
                            width: 100px;
                            height: 48px;
                        }
                    }
                }

                .mobile {
                    padding: 28px 0 0;
                    margin: 0;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
