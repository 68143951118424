@media (max-width: $screen-lg) {
    // lg-md view

    html {
        font-size: 16px;
        line-height: 140%;
    }

    .desktop-only {
        display: none !important;
    }

    .mobile-only {
        display: initial !important;
    }

    .wrapper {
    	min-width: 720px;
    	max-width: 100%;
    }

    .wrapper-inside {
        width: 93.75%;
    }

    /* Header
    -----------------------------------------------------------------------------*/
    .header {

    }

    /* Middle
    -----------------------------------------------------------------------------*/
    .content {
        padding: 0 0 $footer-size-md;
    }

    /* Footer
    -----------------------------------------------------------------------------*/
    .footer {
        margin: -$footer-size-md auto 0;
        height: $footer-size-md;
    }

    h1, .h1 {
        font-size: 48px;
    }

    h2, .h2 {
        font-size: 28px;
        line-height: 38px;
    }

    h3, .h3 {
        font-size: 16px;
        line-height: 22px;
    }

    p {
        font-size: 16px;
    }
}

@media (max-width: $screen-md) {
    // md-view

}
