@media (max-width: $screen-lg) {
    // lg-md view
    .header-holder { 
        .header-top { 
            .header-top-holder { 
                .contact-infoes { 
                    .contact-info { 
                        margin-left: 20px;
                        a {
                            font-size: 14px;
                            .icon {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
