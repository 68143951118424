@import "parameters";
@import "fonts";
@import "mixins";
@import "bootstrap/text-overflow";
@import "bootstrap/text-emphasis";
@import "bootstrap/background-variant";
@import "bootstrap/clearfix";
@import "bootstrap/normalize";
@import "bootstrap/responsive-visibility";
@import "includes/mobile_menu";
@import "includes/custom_radio_checkbox";
@import "includes/slick";
@import "includes/forms";
@import "includes/custom_scroll";
@import "includes/custom_tabs";
@import "includes/pagination";
@import "includes/fancybox/jquery.fancybox.min.css";
@import "includes/popups/custom_fancybox";
@import "includes/selectric/selectric";


/* Eric Meyer's CSS Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {

}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* End of Eric Meyer's CSS Reset */

html {
	height: 100%;
	font-family: $main_font;
	font-style: normal;
	font-weight: normal;
	font-size: $font-size-base;
	line-height: $line-height-computed;
	color: $gray-base;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
	display: block;
}
body {
	height: 100%;
}

.wrapper {
	min-width: 1180px;
	max-width: 100%;
	margin: 0 auto;
	min-height: 100%;
	height: auto;
}

.wrapper-inside {
    width: 1180px;
    margin: 0 auto;
}

/* Basics
-----------------------------------------------------------------------------*/
img, svg {
    vertical-align: top;
}

.clearfix {
    clear: both !important;
}

.hidden {
    display: none !important;
}

.invisible {
    visibility: hidden !important;
}

a {
	color: $brand-secondary;
	text-decoration: none;
	transition: all 300ms;
	&:hover {
		color: $brand-secondary-accent;
		transition: all 300ms;
	}
}


/*buttons*/

.btn {
	text-decoration: none;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	font-family: inherit;
	background: $bg-primary;
	color: $gray-base;
	padding: 10px 10px;
	box-sizing: border-box;
    display: inline-block;
	letter-spacing: 0;
	border-radius: 5px;
	border: 1px solid $bg-primary;
	outline: none;
	transition: all 300ms;
	&:hover {
		cursor: pointer;
		background: $bg-secondary;
		color: $gray-base;
		transition: all 300ms;
	}
}
.btn-primary {
	background: $brand-primary;
	border: 1px solid $brand-primary;
	color: white;
	&:hover {
		background: $brand-primary-accent;
		border: 1px solid $brand-primary-accent;
		color: white;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-primary;
		&:hover {
			background: $brand-primary;
			border: 1px solid $brand-primary;
			color: white;
		}
	}
}
.btn-secondary {
	background: $brand-secondary;
	border: 1px solid $brand-secondary;
	color: white;
	&:hover {
		background: $brand-secondary-accent;
		border: 1px solid $brand-secondary-accent;
		color: white;
	}
	&.btn-outline {
		background: transparent;
		color: $brand-secondary;
		&:hover {
			background: $brand-secondary;
			border: 1px solid $brand-secondary;
			color: white;
		}
	}
}

/*colors*/
.brand-primary-color {
	color: $brand-primary;
}

.brand-primary-accent-color {
	color: $brand-primary-accent;
}

.brand-secondary-color {
	color: $brand-secondary;
}

.brand-secondary-accent-color {
	color: $brand-secondary-accent;
}

.gray-darker-color {
	color: $gray-darker;
}

.gray-dark-color {
	color: $gray-dark;
}

.gray-color {
	color: $gray;
}

.gray-light-color {
	color: $gray-light;
}

.gray-lighter-color {
	color: $gray-lighter;
}

/*bkgs*/

.background-secondary {
	background: $bg-secondary;
	&.reversed {
		background: $brand-secondary;
	}
}

.background-primary {
	background: $bg-primary;
	&.reversed {
		background: $brand-primary;
	}
}


/* Header
-----------------------------------------------------------------------------*/
.header {
	height: $header-size;
	background: white;
	position: relative;
	z-index: 1203;
	@import "includes/base/header";
}


/* Middle
-----------------------------------------------------------------------------*/
.content {
	padding: 0 0 $footer-size;
	background: $bg-primary;
}

/* Footer
-----------------------------------------------------------------------------*/
.footer {
	margin: -$footer-size auto 0;
	height: $footer-size;
	background-color: $brand-secondary;
	color: white;
	@import "includes/base/footer";
}

@import "bootstrap/responsive-utilities";
@import "bootstrap/type";

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	&.first {
		margin-top: 0;
	}
}

h2, .h2 {
	line-height: 52px;
}

p {
	font-size: $font-size-base;
	font-weight: 400;
	font-style: normal;
	line-height: 140%;
	margin: 0 0 20px;
}
p.accent {
	font-size: $font-size-large;
	line-height: floor(($font-size-large * $line-height-base));
}
p.small {
	font-size: $font-size-small;
	line-height: floor(($font-size-small * $line-height-base));
}

b, strong {
	font-weight: bold;
}

i, em {
	font-style: italic;
}

small {
	font-size: $font-size-small;
}

mark, ins {
	font-weight: 600;
	color: $brand-primary;
	background: $bg-secondary;
}

s, del {
	text-decoration: line-through;
}

.page-heading {
	display: inline-block;
	width: 100%;
    margin-bottom: 30px;
	.page-heading-holder {
		display: inline-block;
		width: 100%;
		h1, .h1 {
			margin: 0;
		}
	}
}

ul, ol {
	margin: 0;
}

ul.dashes {
	list-style: none;
    padding-left: 18px;
	li {
		position: relative;
		&:before {
			content: "";
			width: 8px;
			height: 4px;
			background: $brand-primary;
			position: absolute;
			left: -18px;
			top: 9px;
		}
	}
}

ol.num {
	counter-reset: section;
	list-style: none;
    padding-left: 23px;
    margin-bottom: 26px;
	li {
		position: relative;
		&::before {
			counter-increment: section;
			content: counter(section) ".";
			position: absolute;
			left: -32px;
			top: 0px;
			width: 29px;
			height: 29px;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: $gray-base;
			text-align: center;
		}
	}
}

@import "media/tablet/base";
@import "media/mobile/base";
