@media (max-width: $screen-sm) {
    // sm-view
    .fancybox-container.custom-fancybox {
        .fancybox-content {
            padding: 10px;
        }
    }
    .general-popup {
        .general { 
            .text {
                p {
                    font-size: 12px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
