@media (max-width: $screen-sm) {
    // sm-view
    .footer-holder { 
        .footer-main { 
            padding: 0 0 17px;
            .footer-main-holder { 
                .company { 
                    width: 100%;
                    .company-holder { 
                        padding: 20px 0 0;
                        .logo {
                            margin: 0 0 16px;
                            a {
                                svg {
                                    width: 100px;
                                    height: 50px;
                                }
                            }
                        }

                        .copyright {
                            font-size: 12px;
                        }
                    }
                }

                .footer-menu { 
                    width: 100%;
                    .footer-menu-holder {
                        padding: 22px 0 0;
                    }
                }

                .contacts-infoes { 
                    width: 100%;
                    padding: 5px 0 0;
                    .contacts-infoes-holder { 
                        .contact-info { 
                            a, .dummy { 
                                width: 100%;
                                float: left;
                                .icon {
                                    display: none;
                                }

                                .text {
                                    width: 100%;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .footer-bottom { 
            .footer-bottom-holder { 
                .studio { 
                    float: left;
                    width: 100%;
                    padding: 8px 0 0;
                    a {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 140%;
                        .icon {
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
