.header-holder { 
    display: inline-block;
    width: 100%;
    height: 100%;
	.header-top { 
        display: inline-block;
        width: 100%;
		.header-top-holder { 
            display: inline-block;
            width: 100%;
            .logo { 
                float: left;
                padding: 12px 0 0;
                a { 
                    display: inline-block;
                    svg { 

                        path { 

                        }
                    }
                }
            }

            .menu { 
                float: left;
                padding: 36px 0 0;
                margin-left: 64px;
                nav { 

                    ul { 
                        display: block;
                        margin: 0;
                        margin-left: -12.5px;
                        padding: 0;
                        list-style: none;
                        li { 
                            float: left;
                            margin: 0 12.5px;
                            a {
                                display: inline-block;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 140%;
                                color: $brand-secondary;
                                position: relative;
                                &:hover {
                                    color: $brand-primary;
                                }
                                &:after {
                                    content: "";
                                    display: inline-block;
                                    width: 100%;
                                    height: 0;
                                    border-top: 1px solid currentColor;
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                    opacity: 0;
                                    transition: all 300ms;
                                }
                                &.current { 
                                    &:hover {
                                        color: $brand-secondary;
                                        cursor: default;
                                    }
                                    &:after {
                                        opacity: 1;
                                        transition: all 300ms;
                                    }
                                }
                            }

                        }
                    }
                }
            }

            .contact-infoes { 
                float: right;
                padding: 37px 0 0;
                .contact-info { 
                    float: left;
                    margin-left: 30px;
                    &:first-of-type {
                        margin: 0;
                    }
                    a { 
                        display: inline-block;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 140%;
                        &:hover {
                            color: $brand-primary;
                        }
                        .icon { 
                            float: left;
                            margin-right: 10px;
                            svg { 
                                margin: -3px 0 0;
                            }
                        }

                        .text { 
                            float: left;
                        }
                    }
                }
            }

            .mobile { 
                float: right;
                padding: 41px 0 0;
                margin-left: 62px;
                width: 24px;
                height: 24px;
                position: relative;
                &.open {
                    .mobile-menu-open {
                        z-index: -1;
                        opacity: 0;
                        transition: all 300ms;
                    }
                    .mobile-menu-close {
                        z-index: 1;
                        opacity: 1;
                        transition: all 300ms;
                    }

                }
                .mobile-menu-open, .mobile-menu-close { 
                    position: absolute;
                    left: 0;
                    top: 28px;
                    z-index: -1;
                    opacity: 0;
                    transition: all 300ms;
                }
                .mobile-menu-close {
                    top: 19px;
                }
                .mobile-menu-open {
                    z-index: 1;
                    opacity: 1;
                    transition: all 300ms;
                }



            }
        }
			
	}
}

@import "../../media/tablet/includes/base/header";
@import "../../media/mobile/includes/base/header";
