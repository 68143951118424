@media (max-width: $screen-lg) {
    // lg-md view
    .general-popup {
        .general { 
            .text {
                p {
                    font-size: 16px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
